<div class="dialog-header">
  <div class="dialog-title-text">
    <mat-icon class="dialog-title-icon">login</mat-icon>
    <span mat-dialog-title>Connexion / inscription</span>
  </div>
  <div class="dialog-title-close">
    <button
      mat-icon-button
      mat-dialog-close
      class="close-btn"
      matTooltip="Close"
      matTooltipPosition="left"
      (click)="successCallback(false)"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content>
  <div class="row" *ngIf="data?.message">
    <div class="col-12 message">
      {{ data?.message }}
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <p class="login-dialog-connexion">
        Si vous n'avez pas de compte, vous pouvez en créer un ci-dessous ou
        utiliser un compte social (exemple Google ou Facebook). Sinon entrez
        votre login et mot de passe pour vous connecter via votre compte "liste
        d'envies".
      </p>
      <ngx-auth-firebaseui
        [providers]="[]"
        [guestEnabled]="false"
        (onSuccess)="successCallback($event)"
        appearance="Apparence"
        loginButtonText="S'identifier"
        emailText="Email"
        emailErrorRequiredText="L'email est requis"
        emailErrorPatternText="S'il vous plaît, mettez une adresse email valide"
        passwordText="Mot de passe"
        passwordErrorRequiredText="Le mot de passe est requis"
        signInTabText="Se connecter"
        signInCardTitleText="Connectez-vous avec un compte 'liste d'envies'"
        forgotPasswordButtonText="Mot de passe oublié ?"
        nameText="Nom"
        nameErrorRequiredText="Le nom est requis"
        nameErrorMinLengthText="Le nom est trop court !"
        nameErrorMaxLengthText="Le nom est trop long !"
        registerTabText="Créer un compte"
        registerCardTitleText="Creér un compte 'liste d'envies'"
        registerButtonText="Créer"
        guestButtonText="continuer en tant qu'invité"
        resetPasswordTabText="Réinitialisation du mot de passe par email"
        resetPasswordInputText="Réinitialisation du mot de passe par email"
        resetPasswordErrorRequiredText="Un email est requis pour réinitialiser le mot de passe !"
        resetPasswordErrorPatternText="S'il vous plaît, mettez une adresse email valide"
        resetPasswordActionButtonText="Réinitialiser"
        resetPasswordInstructionsText="Réinitialisation demandée. Suivez les instructions du courrier électronique qui vous a été envoyé."
        emailConfirmationTitle="Confirmez votre adresse email !"
        emailConfirmationText="Un email de confirmation vous a été envoyé.\n   Vérifiez votre boîte de réception et cliquez sur le lien Confirmer mon adresse e-mail pour confirmer votre adresse email."
        verifyEmailTitleText="Confirmez votre adresse email !"
        verifyEmailConfirmationText="Un email de confirmation vous a été envoyé.\n   Vérifiez votre boîte de réception et cliquez sur le lien Confirmer mon adresse e-mail pour confirmer votre adresse email."
        verifyEmailGoBackText="Retour"
        sendNewVerificationEmailText="Envoyez un nouveau email de confirmation"
        signOutText="Déconnexion"
        messageOnAuthSuccess="Vous êtes bien connecté, vous pouvez maintenant utiliser 'liste d'envies'"
        messageOnAuthError="Une erreur lors de la connexion! Merci de réessayer!"
      ></ngx-auth-firebaseui>
    </div>
    <div class="col-1"></div>
    <div class="col-1 divider"></div>
    <div class="col-4">
      <p class="login-dialog-connexion">
        Vous pouvez aussi vous connecter ou vous inscrire à partir de vos
        comptes suivants :
      </p>
      <ngx-auth-firebaseui-providers
        [providers]="providers"
        [theme]="theme"
      ></ngx-auth-firebaseui-providers>
      <p class="login-dialog-subdescription">
        Si vous aviez déjà un compte sur la version 1 de liste d'envies, vous
        devez utiliser la connection via votre compte Google.
      </p>
    </div>
  </div>
</mat-dialog-content>

<!--
signInTabText="Se connecter"
                      signInCardTitleText="Connectez-vous"

forgotPasswordButtonText="Mot de passe oublié ?"
                      nameText="Nom"
                      nameErrorRequiredText="Le nom est requis"
                      nameErrorMinLengthText="Le nom est trop court !"
                      nameErrorMaxLengthText="Le nom est trop long !"
registerTabText="Créer un compte"
                      registerCardTitleText="Enregistrement"
                      registerButtonText="Créer"
                      guestButtonText="continuer en tant qu'invité"
                      resetPasswordTabText="Réinitialisation du mot de passe par email"
                      resetPasswordInputText="Réinitialisation du mot de passe par email"
                      resetPasswordErrorRequiredText="Un email est requis pour réinitialiser le mot de passe !"
                      resetPasswordErrorPatternText="S'il vous plaît, mettez une adresse email valide"
                      resetPasswordActionButtonText="Réinitialiser"
                      resetPasswordInstructionsText="Réinitialisation demandée. Suivez les instructions du courrier électronique qui vous a été envoyé."
                      emailConfirmationTitle="Confirmez votre adresse email !"
                      emailConfirmationText="Un email de confirmation vous a été envoyé.\n   Vérifiez votre boîte de réception et cliquez sur le lien Confirmer mon adresse e-mail pour confirmer votre adresse email."
-->
