<form
  fxLayout="row nowrap"
  fxLayout.lt-lg="row wrap"
  fxLayoutAlign="space-between center"
  fxLayoutGap="10px"
  fxLayoutGap.lt-md="2px"
  [formGroup]="filtersForm"
  class="form-filters"
>
  <mat-button-toggle-group
    #typeWishToogle="matButtonToggleGroup"
    formControlName="type"
    class="filterWishToggle"
  >
    <mat-button-toggle
      value="all"
      matTooltip="Afficher toutes les envies de la liste"
      >Tous</mat-button-toggle
    >
    <mat-button-toggle
      value="to_give"
      matTooltip="Afficher seulement les envies qui restent à offrir"
      >A offrir</mat-button-toggle
    >
    <mat-button-toggle
      value="given"
      matTooltip="Afficher seulement les envies déjà offert"
      >Offert</mat-button-toggle
    >
    <mat-button-toggle
      value="suggest"
      matTooltip="Afficher seulement les suggestions d'envies"
      >Suggestions</mat-button-toggle
    >
  </mat-button-toggle-group>

  <mat-button-toggle-group
    #commentToogle="matButtonToggleGroup"
    formControlName="comment"
    class="filterWishToggle"
  >
    <mat-button-toggle
      value="comment"
      matTooltip="Afficher seulement les envies qui ont des commentaires"
      >Commentaires</mat-button-toggle
    >
  </mat-button-toggle-group>

  <mat-form-field appearance="fill" class="filters-wish-formfield">
    <mat-label>Recherche</mat-label>
    <input
      matInput
      placeholder="Un mot clé à rechercher ..."
      formControlName="search"
    />
    <mat-icon matSuffix>search</mat-icon>
    <mat-hint>Recherche dans les envies de la liste</mat-hint>
  </mat-form-field>

  <mat-form-field appearance="fill" class="filters-wish-formfield">
    <mat-label>Trier</mat-label>
    <mat-select placeholder="Trier par" formControlName="sort">
      <mat-option value="+label">Titre - A à Z</mat-option>
      <mat-option value="-label">Titre - Z à A</mat-option>
      <mat-option value="+price">Prix - moins cher</mat-option>
      <mat-option value="-price">Prix - plus cher</mat-option>
      <mat-option value="+date">date - plus récent</mat-option>
      <mat-option value="-date">date - plus ancien</mat-option>
      <mat-option value="+rating">Note - 1 étoile</mat-option>
      <mat-option value="-rating">Note - 5 étoiles</mat-option>
    </mat-select>
    <mat-hint>Trier les envies par</mat-hint>
  </mat-form-field>
</form>
<div>
  <mat-chip-list>
    <mat-chip
      removable="true"
      (removed)="removeFilter(filter.id)"
      *ngFor="let filter of filters | async"
      matTooltip="Supprimer ce filtre"
      >{{ filter.name }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
</div>
