<a
  [class.owner]="wishlist.owner"
  [class.active]="active"
  [matTooltip]="
    wishlist.description
      ? (wishlist.description | stripTags | truncate: 200:'...':true)
      : null
  "
  [routerLink]="active ? null : ['/', wishlist.name]"
  matRipple
  class="wish-list-item list-envies-item"
  fxLayout="column"
  fxLayoutAlign="space-between stretch"
  matTooltipPosition="above"
>
  <div
    class="backgroundimg"
    [style.backgroundImage]="'url(/assets/thumb/' + wishlist.picture + ')'"
    *ngIf="wishlist.picture"
  ></div>
  <div
    class="wish-list-item-text"
    fxLayout="row"
    fxLayoutAlign="space-between stretch"
  >
    <list-type-icon [type]="wishlist.type"></list-type-icon>
    <h2 class="wish-list-item-heading" fxFlex="grow">
      {{ wishlist.title }}
    </h2>
    <span class="notification-list" *ngIf="notifsCount$ | async as notifsCount">
      <mat-icon
        [matBadge]="notifsCount"
        color="accent"
        matBadgeSize="medium"
        matBadgeColor="primary"
        >notifications_active</mat-icon
      >
    </span>
  </div>

  <div
    class="wish-list-item-text"
    fxLayout="row wrap"
    fxLayoutAlign="start start"
  >
    <span>de</span>
    <ng-container *ngFor="let owner of wishlist.owners; let last = last">
      <ngx-avatar
        [gravatarId]="owner.email"
        [matTooltip]="owner.name"
        [name]="owner.name || owner.email"
        [round]="true"
        [src]="owner.photoURL"
        matTooltipPosition="above"
        size="15"
      >
      </ngx-avatar>
      <span
        [matTooltip]="owner.email"
        [title]="owner.email"
        class="owners"
        matTooltipPosition="above"
        >{{ owner.name || owner.email }}</span
      ><span *ngIf="!last">, </span></ng-container
    >
  </div>
</a>
