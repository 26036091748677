<div>
  <div class="page-background"></div>
  <mat-card class="header-page-card">
    <section class="mat-typography">
      <div *ngIf="userAuth | async as user; else showLogin">
        <h1>
          Bonjour {{ user.displayName }}! Bienvenue sur vos listes d'envies
        </h1>
      </div>
      <ng-template #showLogin>

        <div class="row">
          <div class="col-4">
            <img
              src="/assets/images/undraw_gift_card_6ekc.png"
              class="wishlist-img"
            />
          </div>
          <div class="col-8">
            <h1 class="intro1">
              La liste de toutes vos envies de cadeaux à partager avec vos
              proches !
            </h1>
            <h2 class="intro2">
              <b>Sans doublon</b> et <b>Surprise garantie</b> jusqu'au jour j !
            </h2>

            <p class="intro3">
              Votre famille, vos amis peuvent indiquer quel(s) cadeau(x) ils
              souhaitent vous offrir dans votre liste d'envies, se mettre
              d'accord les uns avec les autres (en évitant les doublons! ) sans
              que vous le sachiez.
            </p>

            <a mat-button color="primary" routerLink="/about">
              En savoir plus
            </a>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
        <br />
        <br />

        <div class="row">
          <div class="col-6">
            <p>
              Vous pouvez vous connecter / ou créer un compte pour commencer à
              créer votre liste.
            </p>
            <a mat-raised-button color="primary" (click)="connect()">
              Connexion / créer un compte
            </a>
          </div>
          <div class="col-1 divider"></div>
          <div class="col-5">
            <p>
              Vous pouvez aussi vous connecter ou vous inscrire à partir de vos
              comptes sociaux suivants :
            </p>
            <ngx-auth-firebaseui-providers
              [providers]="providers"
              [theme]="theme"
            ></ngx-auth-firebaseui-providers>
          </div>
        </div>

      </ng-template>
    </section>
  </mat-card>

  <span class="done" *ngIf="userAuth | async">
    <button
      mat-fab
      matTooltip="Créer une nouvelle liste d'envies"
      matTooltipPosition="left"
      routerLink="/addList"
    >
      <mat-icon>playlist_add</mat-icon>
    </button>
  </span>
  <mat-grid-list
    *ngIf="!(loading$ | async); else loading"
    [cols]="column$ | async"
    rowHeight="80"
    gutterSize="4"
    class="whishList-Container"
  >
    <mat-grid-tile *ngFor="let wishList of list$ | async">
      <wish-list-item class="wishListCard" [wishlist]="wishList">
      </wish-list-item>
    </mat-grid-tile>
  </mat-grid-list>

  <ng-template #loading>
    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
</div>
